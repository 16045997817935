<template>
  <tr :class="computedClasses">
    <td :data-testid="labelDataTestid">
      <slot name="label">
        {{ label }}
        <ZTooltipIcon
          v-if="tooltip"
          :content="tooltip"
          :title="label"
          icon-size="sm"
          class="ml-1"
        />
      </slot>
    </td>
    <td :data-testid="valueDataTestid">
      <slot name="value">
        {{ value }}
      </slot>
    </td>
  </tr>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  label?: string
  value?: string
  tooltip?: string
  nested?: number
  total?: boolean
  subtotal?: boolean
  discount?: boolean
  isClickable?: boolean
  labelDataTestid?: string
  valueDataTestid?: string
}>(), {
  label: '',
  value: '',
  tooltip: '',
  nested: 0,
})

const computedClasses = computed(() => [
  'ztable-line-item',
  {
    'total': props.total,
    'discount': props.discount,
    'subtotal': props.subtotal,
    'is-clickable': props.isClickable,
    [`nested-${props.nested}`]: props.nested,
  },
])
</script>
